import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { IconButtonCellRendererComponent } from "src/app/_reusable/cell-renderers/icon-button-cell-renderer/icon-button-cell-renderer.component";
import { ShipmentSummaryCellRendererComponent } from "src/app/_reusable/cell-renderers/shipment-summary-cell-renderer/shipment-summary-cell-renderer.component";
import { GridColumnPartialsHelper } from "src/app/_reusable/grid-column-partial-helper";
import { Observable, forkJoin, throwError } from 'rxjs';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { RfpMgmtApiService, GetAccountRfpsQuery, GetAccountAgreementsResp, GetAccountRfpsResp, AccountAgreement } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpSummary } from 'src/app/_models/rfp/rfpSummary';
import { LookupService } from 'src/app/_services/lookup.service';
import { UrlService } from 'src/app/_services/url.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RfpCreateDialogComponent } from 'src/app/_reusable/dialogs/rfp-create-dialog/rfp-create-dialog.component';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigSettings } from 'src/app/_models/configSettings';
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';
import { DownloadCellRendererComponent } from 'src/app/_reusable/cell-renderers/download-cell-renderer/download-cell-renderer.component';
import { GetPricingAgreementReportFileQuery, PricingApiService } from '@xpo-ltl/sdk-pricing';
import { catchError, tap } from 'rxjs/operators';
import { FileContents } from '@xpo-ltl/sdk-common';
import { XpoSnackBarConfig } from '@xpo-ltl/ngx-ltl-core/snack-bar/models/xpo-snack-bar-config.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountHierarchyComponent } from 'src/app/_reusable/account-hierarchy/account-hierarchy.component';
import { RfpConfigService, RfpMgmtConfig } from 'src/app/_services/rfp.config.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  @ViewChild(AccountHierarchyComponent) hierarchyComponent!: AccountHierarchyComponent;
  
  customerSearch: string = localStorage.getItem("dashboard-mad-code") ?? "";
  showDevTool: boolean = false;
  prcRfpAppUrl: string = "";
  isProduction: boolean = false;

  AccountAgreementColDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.RowIndex,
    { field: 'download',           headerName: '',   cellRendererFramework: DownloadCellRendererComponent, cellRendererParams: { fileName: this.getAgreementFileName.bind(this), fileDownloadHandler: this.downloadAgreement.bind(this), tooltip: "Download Agreement" }, ...GridColumnPartialsHelper.XpoStatusColDef,  },
    { field: 'shipmentSummary',    headerName: '',   cellRendererFramework: ShipmentSummaryCellRendererComponent, cellRendererParams: { onClickCallback: this.openShipmentSummary.bind(this), tooltip: "View Shipment Summary",  iconName:"shipment" }, ...GridColumnPartialsHelper.XpoStatusColDef,  },
    { field: "agreementId",        headerName: "Agreement ID",             filter: 'agTextColumnFilter',},
    { field: "agreementNumber",    headerName: "Agreement",                filter: 'agTextColumnFilter',},
    { field: "salesRfpId",         headerName: "Sales RFP ID",             filter: 'agTextColumnFilter',},
    { field: "salesOwnerFullName", headerName: "Sales Owner",              filter: 'agTextColumnFilter',},
    { field: "accountType",        headerName: "Account Type",             filter: 'agTextColumnFilter',},
    { field: "salesRegionCode",    headerName: "Region",                   filter: 'agTextColumnFilter', hide: true},
    { field: "effectiveDate",      headerName: "Effective Date",           filter: false, ...GridColumnPartialsHelper.DateCell("MM/DD/YYYY"), },
    { field: "renegotiationDate",  headerName: "Renegotiation Date",       filter: false, ...GridColumnPartialsHelper.DateCell("MM/DD/YYYY"), },
    { field: "expirationDate",     headerName: "Expiration Date",          filter: false, ...GridColumnPartialsHelper.DateCell("MM/DD/YYYY"), sortIndex:0, sort:"desc"},
    { field: "isActive",           headerName: "Is Active",                filter: 'agSetColumnFilter',	...GridColumnPartialsHelper.BooleanCell() },
  ]
  
  AccountRfpsColDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.RowIndex,
    { field: 'openRfp',                   headerName: '',   cellRendererFramework: IconButtonCellRendererComponent, cellRendererParams: { onClickCallback: this.navigateToRfp.bind(this), tooltip: "Open RFP", iconName:"edit" }, ...GridColumnPartialsHelper.XpoStatusColDef,  },
    { field: 'openRfpNewTab',             headerName: '',   cellRendererFramework: IconButtonCellRendererComponent, cellRendererParams: { onClickCallback: this.openRfpInNewWindow.bind(this), tooltip: "Open RFP In New Tab", iconName:"open-in-new" }, ...GridColumnPartialsHelper.XpoStatusColDef,  },
    { field: "rfpType",                   headerName: "Rfp Type",             filter: 'agSetColumnFilter'},
    { field: "salesProgram",              headerName: "Sales Pgm",            filter: 'agSetColumnFilter'},
    { field: "salesRfpId",                headerName: "Rfp ID",               filter: 'agTextColumnFilter', },
    { field: "status",                    headerName: "Status",               filter: 'agSetColumnFilter'},
    { field: 'daysSinceLastStatusUpdate',	headerName: "Days In Queue",        filter: 'agNumberColumnFilter', hide: true, },
    { field: "reasonCode",                headerName: "Return Reason",        filter: 'agSetColumnFilter'},
    { field: "salesAgentFullName",        headerName: "Sales Agent",          filter: 'agTextColumnFilter', },
    { field: "pricingAnalystFullName",    headerName: "Pricing Analyst",      filter: 'agTextColumnFilter', }, 
    { field: "requestDate",               headerName: "Request Date",         filter: false, ...GridColumnPartialsHelper.DateCell("MM/DD/YYYY"), },
    { field: "dueDate",                   headerName: "Due Date",             filter: false, ...GridColumnPartialsHelper.DateCell("MM/DD/YYYY"), },
    { field: "renegotiationDate",         headerName: "Renegotiation Date",   filter: false, ...GridColumnPartialsHelper.DateCell("MM/DD/YYYY"), },
    { field: "isActive",                  headerName: "Is Active",            filter: 'agSetColumnFilter',	...GridColumnPartialsHelper.BooleanCell(), width: 100 },
    { field: "isDpt",                     headerName: "Is DPT",               filter: 'agSetColumnFilter',	...GridColumnPartialsHelper.BooleanCell(), width: 100 },
  ]

  agreementGridApi: GridApi;
  rfpGridApi: GridApi;
  gridLoadingData: boolean = false;

  agreementGridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: true,
    },
    rowSelection: 'multiple',
    maintainColumnOrder: true,
    columnDefs: this.AccountAgreementColDefs,
    getRowNodeId: (data) => data.agreementId,
  } 

  rfpGridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: true,
    },
    rowSelection: 'multiple',
    maintainColumnOrder: true,
    columnDefs: this.AccountRfpsColDefs,
    getRowNodeId: (data) => data.salesRfpId,
  }

  constructor(private rfpApi: RfpMgmtApiService, private pricingApi: PricingApiService,  public lookup: LookupService, private router: Router, private snackBar: XpoSnackBar, private urlService: UrlService, private dialog: MatDialog, private config: ConfigManagerService, private rfpConfigService: RfpConfigService, private userService: UserService) {}
  
  ngOnInit(): void {
    this.prcRfpAppUrl = this.config.getSetting(ConfigSettings.PrcAgmtAppUrl);
    this.isProduction = this.config.getSetting(ConfigSettings.Production);

    const requests = {
      rfpUser: this.userService.getRfpUser(),
    }

    forkJoin(requests).subscribe({
      next: results => {
        const rfpUser = results.rfpUser;
        const showCreateRfp = this.rfpConfigService.getConfigValue(RfpMgmtConfig.ShowCreateRfp, false);
        if(this.isProduction){
          this.showDevTool = showCreateRfp && (rfpUser?.applicationOptions?.createRfp ?? false);
        }
        else{
          this.showDevTool = showCreateRfp;
        }
      },
      error: err => {
        this.snackBar.open({
          message: `There was an issue retreiving list of rfp config field and/or logged in user details.`,
          detailedMessage: "Please try again.",
          status: "error"
        })
      }
    });
  }

  onAgreementGridReady(event: GridReadyEvent): void {
    this.agreementGridApi = event.api;
    if(this.agreementGridApi && this.rfpGridApi && this.customerSearch?.length > 0) this.searchCustomer();
  } 

  onRfpGridReady(event: GridReadyEvent): void {
    this.rfpGridApi = event.api;
    this.loadPremiumServices();
    if(this.agreementGridApi && this.rfpGridApi && this.customerSearch?.length > 0) this.searchCustomer();
  }

  loadPremiumServices() {
    this.lookup.premiumServices$.subscribe({
      next: (premiumServices) => {
        const premiumServiceColumns = premiumServices
          .sort((a, b) => a.premiumServiceTypeCode.localeCompare(b.premiumServiceTypeCode))
          .map((service) => {
            return {
              headerName: service.premiumServiceTypeCode,
              field: service.premiumServiceTypeCode,
              ...GridColumnPartialsHelper.BooleanCell(true, false, true, false),
              width: 100,
              columnGroupShow: service.premiumServiceTypeCode !== "MABD" ? "open" : undefined
            } as ColDef;
          });

        const premiumServiceGroup: ColGroupDef = {
          headerName: 'Premium Services',
          children: premiumServiceColumns,
        };

        this.AccountRfpsColDefs.push(premiumServiceGroup); // Put the group at the end of the grid

        if (this.rfpGridApi) {
          this.rfpGridApi.setColumnDefs(this.AccountRfpsColDefs);
        }
      }
    });
  }

  searchCustomer(){
    localStorage.setItem("dashboard-mad-code", this.customerSearch)

    this.agreementGridApi.showLoadingOverlay()
    this.rfpGridApi.showLoadingOverlay()
    
    if(this.customerSearch?.length > 0){
      this.gridLoadingData = true;

      
      let queryParams = new GetAccountRfpsQuery();
      queryParams.excludeDpt = false;
      queryParams.excludeNonDpt = false;

      this.hierarchyComponent?.getHierarchy();

      forkJoin([this.rfpApi.getAccountAgreements({madCode: this.customerSearch}), this.rfpApi.getAccountRfps({madCode: this.customerSearch}, queryParams), this.lookup.rfpTypes$, this.lookup.rfpStatuses$, this.lookup.salesPrograms$, this.lookup.salesReturnReasons$, this.lookup.pricingReturnReasons$]).subscribe(
        (results: [GetAccountAgreementsResp, GetAccountRfpsResp, LookupCodeNamePair[], LookupCodeNamePair[], LookupCodeNamePair[], LookupCodeNamePair[], LookupCodeNamePair[]]) => {
          let agreements: AccountAgreement[] = results[0].items;
          let rfps: RfpSummary[] = results[1].items;
          let rfpTypes: LookupCodeNamePair[] = results[2];
          let rfpStatuses: LookupCodeNamePair[] = results[3];
          let salesPrograms: LookupCodeNamePair[] = results[4];
          let salesReturnReasons: LookupCodeNamePair[] = results[5];
          let pricingReturnReasons: LookupCodeNamePair[] = results[6];

          this.agreementGridApi.setRowData(agreements);

          this.rfpGridApi.setRowData(rfps.map((rfpSummary: RfpSummary) => {
            rfpSummary.premiumServiceTypeCodes?.forEach(code => {
              rfpSummary[code] = true;
            });
            return { 
              ...rfpSummary, 
              rfpType: rfpTypes.find(x => x.code?.toLocaleLowerCase() === rfpSummary.rfpType?.toLocaleLowerCase())?.name ?? rfpSummary.rfpType,
              status: rfpStatuses.find(x => x.code?.toLocaleLowerCase() === rfpSummary.status?.toLocaleLowerCase())?.name ?? rfpSummary.status,
              salesProgram: salesPrograms.find(x => x.code?.toLocaleLowerCase() === rfpSummary.salesProgram?.toLocaleLowerCase())?.name ?? rfpSummary.salesProgram,
              reasonCode: this.getReasonCodeName(rfpSummary.reasonCode, salesReturnReasons, pricingReturnReasons),
            }
          }))
        },
        (error) => {
          this.snackBar.open({
            message: `There was an issue retreiving account data for ${this.customerSearch}`,
            detailedMessage: "Please make sure you have the correct MAD Code and try again.",
            status: "error"
          })
          this.agreementGridApi.setRowData(null)
          this.rfpGridApi.setRowData(null)
        },
        () => this.gridLoadingData = false);
      }
      else {
        this.agreementGridApi.setRowData(null)
        this.rfpGridApi.setRowData(null)
      }
  }

  /* Try and find the given reason code first in Sales Reasons, if not there then Pricing Reasons, if not there then just return the original code. */
  getReasonCodeName(code: string, salesReasons: LookupCodeNamePair[], pricingReasons: LookupCodeNamePair[]){
    return salesReasons.find(x => x.code === code)?.name ?? pricingReasons.find(x => x.code === code)?.name ?? code;
  }

  getAgreementFileName(agreement: AccountAgreement): string {
    return `Pricing Agreement - ${agreement.accountNumber}-${agreement.agreementSequenceNumber.toString().padStart(2, '0')}-${agreement.agreementCustomerVersion.toString().padStart(3, '0')}.pdf`
  }

  downloadAgreement(agreement: AccountAgreement): Observable<FileContents>{
    const params: Partial<GetPricingAgreementReportFileQuery> = {
      leadAcctId: agreement.accountNumber.toString(),
      agreementSeqNbr: agreement.agreementSequenceNumber.toString(),
      agreementAcctVersion: agreement.agreementCustomerVersion.toString()
    }

    const request = this.pricingApi.getPricingAgreementReportFile(params as any, null, {responseType: "blob"}).pipe(
      tap(response => {
        this.snackBar.success(`Successfully Downloaded: "${this.getAgreementFileName(agreement)}"`)
      }),
      catchError((error: HttpErrorResponse) => {
        const config: XpoSnackBarConfig = {
          message: `Failed to Download Agreement: "${this.getAgreementFileName(agreement)}"`,
          status: "error"
        }
        this.snackBar.open(config)
        return throwError(error);
      })
    );

    return request;
  }

  navigateToRfp(rowNode: RowNode){
    this.urlService.isInternalRouting = true;
    const rfpId = rowNode.id
    const url = `/rfp/${rfpId}`;
    this.router.navigateByUrl(url)
  }

  openRfpInNewWindow(rowNode: RowNode){
    const rfpId = rowNode.id
    const url = `/rfp/${rfpId}`;
    window.open(url, '_blank');
  }

  openCreateRfpDialog(){
    this.dialog.open(RfpCreateDialogComponent);
  }

  openShipmentSummary(rowNode: RowNode){
    if(rowNode?.data){
      let accountAgreement: AccountAgreement = rowNode.data;
      let url = `${this.prcRfpAppUrl}/standalone-rfp/5/${accountAgreement.accountNumber}/${accountAgreement.agreementSequenceNumber}`;
      window.open(url, '_blank')
    }
  }

  openPlDocs(){
    let urlBase = ""
    let plDocsurl = "appjs/pricing/#/PLDocs/tabs/documentSearch"
    if(this.config.getSetting(ConfigSettings.Production)){
      urlBase = "https://app.ltl.xpo.com/"
    }
    else {
      urlBase = "https://tctss-app.ltl.xpo.com/"
    }
    
    window.open(`${urlBase}${plDocsurl}`)
  }
}
