<xpo-contextual-header>
    <xpo-contextual-header-items [itemList]="contextualHeaderItems"></xpo-contextual-header-items>

    <xpo-contextual-header-actions [config]="actionsConfig"></xpo-contextual-header-actions>

    <div class="header-actions" *ngIf="rfpService.isEditable() || showReOpenButton()">
        <div class="action-button-container">
            <ng-container *ngIf="rfpService.isEditable()">
                <button mat-flat-button xpoButton xpoSmallButton class="submit-button" [matTooltip]="getSubmitToDynamicPricingTooltip()" [disabled]="!canSubmitToDynamicPricing() || savingRfpData" (click)="submitToDynamicPricing()">
                    <xpo-busy-loader *ngIf="makingApiCall" style="height: 20px; width: 20px"></xpo-busy-loader>Submit to Dynamic Pricing
                </button>
                <button xpo-action-button [disabled]="!canAllSave() || makingApiCall || savingRfpData" [matTooltip]="'Save RFP'" (click)="callSaveRfpData()"><xpo-icon iconName="save"></xpo-icon></button>
                <button xpo-action-button [disabled]="makingApiCall || savingRfpData" [matTooltip]="'Close RFP'" (click)="closeRfp()"><xpo-icon iconName="cancel-outline"></xpo-icon></button>
            </ng-container>

            <ng-container *ngIf="showReOpenButton()">
                <button mat-flat-button xpoButton xpoSmallButton class="submit-button" [disabled]="makingApiCall || savingRfpData" (click)="reOpenRfp()"><xpo-busy-loader *ngIf="makingApiCall" style="height: 20px; width: 20px"></xpo-busy-loader>Re-Open RFP</button>
            </ng-container>
        </div>
    </div>
</xpo-contextual-header>

<div class="content-container">

    <xpo-tile-ribbon xpoTileRibbonCompact>
        <xpo-tile *ngFor="let tile of tiles" [tile]="tile"></xpo-tile>
    </xpo-tile-ribbon>

    <div class="field-container">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" xpoAlternateTabs [disableRipple]="true">

            <mat-tab *ngVar="saveableChildren?.get(0)?.getTabStatus() as tabStatus">
                <ng-template mat-tab-label>
                    General
                    <ng-container *ngIf="tabStatus?.complete; else tabInvalid">
                        <xpo-icon iconName="checkmark-alt" class="tab-success"></xpo-icon>
                    </ng-container>

                    <ng-container *ngIf="tabStatus?.unsavedChanges">
                        <ng-container *ngTemplateOutlet="tabUnsavedChanges"></ng-container>
                    </ng-container>
                </ng-template>

                <div class="tab-container">
                    <rfp-dpt #savable (rfpSave)="onChildSave($event)" [rfpDetails]="rfpDetails"></rfp-dpt> 
                </div>
            </mat-tab>

            <mat-tab *ngVar="saveableChildren?.get(1)?.getTabStatus() as tabStatus" [disabled]="rfpDetails.isChangeAccessorial()">
                <ng-template mat-tab-label>
                    Locations
                    <ng-container *ngIf="tabStatus?.complete; else tabInvalid">
                        <xpo-icon iconName="checkmark-alt" class="tab-success"></xpo-icon>
                    </ng-container>
                </ng-template>

                <div class="tab-container">
                    <div *ngIf="!enableDptLocations">
                        <xpo-snack-bar  status="info" [hideClose]="true" message="At this time Dynamic Pricing RFPs automatically apply to all locations in the hierarchy, this is simply for visibility."></xpo-snack-bar>
                    </div>

                    <rfp-locations #savable [account]="accountOverview" [rfpDetails]="rfpDetails" [locked]="isLocationsLocked()"></rfp-locations>
                </div>
            </mat-tab>

            <mat-tab label="Documents">
                <div class="tab-container">
                    <rfp-documents [rfpDetails]="rfpDetails"></rfp-documents>
                </div>
            </mat-tab>


            <mat-tab label="Messages">
                <div class="tab-container no-side-padding">
                    <rfp-messages  [containerHeight]="'403px'" [rfpDetails]="rfpDetails" (onDocumentSelected)="navigateToDocumentTab($event)"></rfp-messages>
                </div>
            </mat-tab>


            <mat-tab label="History">
                <div class="tab-container">
                    <rfp-history [rfpDetails]="rfpDetails"></rfp-history>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
  
<ng-template #tabInvalid>
    <xpo-icon iconName="priority-high" class="tab-error"></xpo-icon>
</ng-template>

<ng-template #tabUnsavedChanges>
    <xpo-icon iconName="save" class="tab-normal"></xpo-icon>
</ng-template>

<ng-container *ngIf="submittingToDynamicPricing">
    <div class="saving-overlay">
        <div class="saving-container">
            <h1>Submitting RFP to Dynamic Pricing</h1>
            <h1>Please Wait...</h1>
            <xpo-busy-loader></xpo-busy-loader>
        </div>
    </div>
</ng-container>