import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CellEditingStoppedEvent, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowNode, RowSelectedEvent } from 'ag-grid-community';
import { AccountOverview } from 'src/app/_models/temp/rfpAccount';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { LookupService } from 'src/app/_services/lookup.service';
import { Accessorial, GetAgreementAccessorialsPath, RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpAccessorial } from 'src/app/_models/rfp/rfpAccessoral';
import { ColDef } from "ag-grid-community";
import { GridColumnPartialsHelper } from "src/app/_reusable/grid-column-partial-helper";
import { TabStatus, OnSaveEvent, SaveEvent } from '../../../rfp-edit.component';
import { Observable, Subscription, of } from 'rxjs';
import { PremiumServicesService, PremiumServicesStatus } from '../rfp-premium-services/rfp-premium-services.service';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CellEditionErrorCellRendererComponent, NumericEditorComponent,  XpoInlineEditingBase } from '@xpo-ltl/ngx-ag-grid';
import { RfpService } from 'src/app/_services/rfp.service';
import { RfpConfigService, RfpMgmtConfig } from 'src/app/_services/rfp.config.service';


@Component({
  selector: 'rfp-accessorials',
  templateUrl: './rfp-accessorials.component.html',
  styleUrls: ['./rfp-accessorials.component.scss']
})
export class RfpAccessorialsComponent extends XpoInlineEditingBase implements OnInit, OnDestroy, OnSaveEvent  {
  @Input() account: AccountOverview;
  @Input() rfpDetails: RfpDetails;
  @Output() rfpSave = new EventEmitter<SaveEvent>();
  
  unitOfMeasureMap: { [accessorial: string]: string[]} = {}
  nonPremiumServiceAccessorialCount: number;
  premiumServicesStatus: PremiumServicesStatus;
  premiumServicesSub: Subscription = new Subscription();

  enableTdcSunset: boolean = false;
  hasActiveAgreementHasTDC: boolean = false;

  RfpAccessorialsColDefs: (ColDef)[] = [
    {
      ...GridColumnPartialsHelper.Selection, 
      checkboxSelection: (params) => this.isRowEditable(params.node)
    },
    {
      ...GridColumnPartialsHelper.RowIndex,
    },
    { 
      field: 'accessorialCode',   
      headerName: "Code",             
      filter: "agTextColumnFilter", 
      sort: "asc"
    },
    { 
      field: 'accessorialName',  
      headerName: "Accessorial",      
      filter: "agTextColumnFilter"
    },
    { 
      field: 'unitOfMeasure',     
      headerName: "Unit of Measure",  
      filter: "agSetColumnFilter", 
      suppressPaste: true, 
      cellEditor: 'agRichSelectCellEditor', 
      cellRenderer: 'cellEditionError',
      editable: (params) => this.isEditing && this.isRowEditable(params.node), 
      cellClassRules: { 
        'xpo-AgGrid-editableCell': (params) => this.isEditing && this.isRowEditable(params.node),
        'xpo-AgGrid-editableCell--edited': (params) => params.data && this.isCellEdited(params.data[this.keyField], params.colDef.field), 
        'xpo-AgGrid-editableCell--error': (params) => params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
      }, 
      cellEditorParams: (params) => ({ values: this.unitOfMeasureMap[params.data.accessorialCode] }),
    },
    { 
      field: 'cost',              
      headerName: "Standard Rate (USD)",    
      ...GridColumnPartialsHelper.DollarFormat, 
      filter: "agNumberColumnFilter" 
    },
    { 
      field: 'agreementAmount',   
      headerName: "Agreement Amount (USD)", 
      ...GridColumnPartialsHelper.DollarFormat, 
      filter: "agNumberColumnFilter" 
    },
    { 
      field: 'amount',            
      headerName: "Amount (USD)",           
      filter: "agNumberColumnFilter", 
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        precision: 2,
      },
      editable: (params) => this.isEditing && this.isRowEditable(params.node), 
      cellClassRules: { 
        'xpo-AgGrid-editableCell': (params) => this.isEditing && this.isRowEditable(params.node),
        'xpo-AgGrid-editableCell--edited': (params) => params.data && this.isCellEdited(params.data[this.keyField], params.colDef.field), 
        'xpo-AgGrid-editableCell--error': (params) => params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
      },
      ...GridColumnPartialsHelper.DollarFormat,
    },
    { 
      field: 'isWaived',          
      headerName: "Waived",     
      ...GridColumnPartialsHelper.CheckboxCell(this.onIsWavedCheckboxChanged.bind(this), this.isRowEditable.bind(this)),
    }
  ]

  gridApi: GridApi;
  columnApi: ColumnApi;
  isEditing: boolean;
  gridData: any;
  keyField: string = "accessorialCode";

  gridLoadingData: boolean = false;
  gridOptions: GridOptions ={
    onRowSelected: this.onRowSelectionChanged.bind(this),
    defaultColDef: {
      resizable: true,
      sortable: true,
      flex: 1,
      valueSetter: (params) => {
        this.handleValueSetter(params, this.keyField);
        return true;
      },
      tooltipValueGetter: (params) => {
        const colDef = params.colDef as ColDef;
        return params.data && this.getErrorDescription(params, colDef.field);
      },
      cellRendererParams: (params) => {
        return {
          error: params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
        };
      }
    },
    columnDefs: this.RfpAccessorialsColDefs,
    getRowNodeId: (data) => data.accessorialCode,
    isRowSelectable: this.isRowSelectable.bind(this),
    singleClickEdit: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    rowSelection: "multiple",
    frameworkComponents: {
      numericEditor: NumericEditorComponent,
      cellEditionError: CellEditionErrorCellRendererComponent,
    },
  };

  constructor(
    private rfpApi: RfpMgmtApiService,
    private lookup: LookupService, 
    private premiumService: PremiumServicesService, 
    private snackBar: XpoSnackBar,
    private rfpService: RfpService,
    private rfpConfigService: RfpConfigService
  ) {
    super();

    this.premiumServicesSub.add(
      this.premiumService.premiumServicesStatus$.subscribe((newStatus: PremiumServicesStatus) => {
        this.premiumServicesStatus = newStatus;
        this.handlePremiumServices();
      })
    )

    this.enableTdcSunset = this.rfpConfigService.getConfigValue(RfpMgmtConfig.EnableTdcSunset, false);
  }

  ngOnInit(): void {
    if(this.enableTdcSunset){
      this.checkForTDC();
    }
  }

  ngOnDestroy() {
    this.premiumServicesSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange && this.gridApi) {
      this.getGridData()
    }
  }

  checkForTDC(){
    if(this.rfpDetails.rfpTypeCode === "Reneg" && this.rfpDetails.previousPricingAgreementCustomerId && this.rfpDetails.previousPricingAgreementSequenceNumber) {
      
      const path: GetAgreementAccessorialsPath = {
        primaryAcctInstId: this.rfpDetails.previousPricingAgreementCustomerId,
        sequenceNumber: this.rfpDetails.previousPricingAgreementSequenceNumber
      }

      this.rfpApi.getAgreementAccessorials(path).subscribe({
        next: (response) => {
          if(response.accessorials.find(x => x.accessorialCode === "TDC")){
            this.hasActiveAgreementHasTDC = true;
          }
        }
      })
    }
  }

  setNonPremiumServiceAccessorialCount(): void {
    this.lookup.premiumServices$.subscribe(premAcc => {
      this.nonPremiumServiceAccessorialCount = this.rfpDetails.accessorials.filter(acc => !premAcc.flatMap(x => x.accessorials).map(x => x.code).includes(acc.accessorialCode)).length
    })
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.gridApi.addEventListener('cellEditingStopped', (event: CellEditingStoppedEvent) => this.processCellEdit(event));
    this.getGridData()
  } 
  
  getGridData() {
    this.setNonPremiumServiceAccessorialCount();
    this.gridLoadingData = true;
    this.gridApi.showLoadingOverlay();

    this.lookup.allAccessorials$.subscribe((accessorials: Accessorial[]) => {
      
      let gridData: RfpAccessorial[] = accessorials
      .filter(x => {
        if(this.enableTdcSunset){
          return x.accessorialCode !== "TDC";
        } else {
          return true;
        }
      })
      .map(defaultAccessorial => {

        this.unitOfMeasureMap[defaultAccessorial.accessorialCode] = defaultAccessorial.unitOfMeasures
        
        let changedAccessorial = this.rfpDetails.accessorials.find(y => defaultAccessorial.accessorialCode == y.accessorialCode)

        return {
          salesRfpId: this.rfpDetails.salesRfpId,
          accessorialCode: defaultAccessorial.accessorialCode,
          accessorialName: defaultAccessorial.accessorialName,
          agreementAmount: changedAccessorial?.agreementAmount ?? 0,
          amount: changedAccessorial?.isWaived ? 0 : changedAccessorial?.amount ?? 0,
          isWaived: changedAccessorial?.isWaived ?? false,
          cost: defaultAccessorial.cost,
          selected: changedAccessorial != null,
          unitOfMeasure: changedAccessorial?.unitOfMeasure ?? defaultAccessorial?.defaultUnitOfMeasure ?? 'Shipment'
        }
      })
      this.gridApi.setRowData(gridData);
      this.gridData = gridData;
      this.dataChanges = [];

      gridData.forEach(accessorial => {
        this.gridApi.getRowNode(accessorial.accessorialCode)?.selectThisNode(accessorial.selected)
      })
    }).add(() => {
      this.gridLoadingData = false;
      setTimeout(() => {
        this.handleEditability();
      }, 0);
    })
  }

  isRowEditable(rowNode: RowNode): boolean {
    const accessorial: string = rowNode.id;
    const isDisabled: boolean = this.premiumServicesStatus?.disabledAccessorials?.includes(accessorial)
    const isWaived: boolean = this.premiumServicesStatus?.waivedAccessorials?.includes(accessorial)
    return !isDisabled && !isWaived && this.rfpService.isEditable();
  }

  isRowSelectable(rowNode: RowNode){
    const accessorial: string = rowNode.id;
    const isDisabled: boolean = this.premiumServicesStatus?.disabledAccessorials?.includes(accessorial)
    return !isDisabled
  }

  onRowSelectionChanged(event:  RowSelectedEvent) {
    let nodeData: RfpAccessorial = event.node.data;

    if(this.premiumServicesStatus.waivedAccessorials.includes(nodeData.accessorialCode)){
      event.node.setSelected(true);
      return;
    }

    let savedAmount = event.data.amount !== 0 ? event.data.amount : this.rfpDetails?.accessorials.find(x => x.accessorialCode == event.node.id)?.amount ?? 0

    if(!event.node.isSelected()){
      savedAmount = 0;
    }

    nodeData = {
      ...nodeData, 
      amount:  savedAmount,
      isWaived: event.node.isSelected() && savedAmount == 0
    }

    event.node.setData(nodeData);    
  }

  processCellEdit(event: CellEditingStoppedEvent) {
    
    let changedRowNode: RowNode = event.node;

    if(event.column.getColId() == "amount" && event.newValue.toString() !== event.oldValue.toString()){
      
      let nodeData: RfpAccessorial = changedRowNode.data;
      let newAmount: number = event.newValue === "" ? 0 : parseFloat(event.newValue)
      
      nodeData = {
        ...nodeData, 
        amount: newAmount, 
        isWaived: newAmount === 0 && changedRowNode.isSelected()
      }

      changedRowNode.selectThisNode(true);
      changedRowNode.setData(nodeData);
    }
  }

  onIsWavedCheckboxChanged(newValue: boolean, node: RowNode) {
    let savedAmount = this.rfpDetails?.accessorials.find(x => x.accessorialCode == node.id)?.amount ?? 0
    let nodeData: RfpAccessorial = { ...node.data, amount: savedAmount, isWaived: newValue };

    if(node.isSelected()) {
      if(newValue) {
        nodeData = {
          ...nodeData, 
          amount: 0
        }
      }
      
      node.setData(nodeData);
    }
    else {
      node.setData(nodeData);
      node.selectThisNode(true);
    } 
  }

  getTabStatus(): TabStatus {
    return { 
      complete: true, 
      canSave: true,
      unsavedChanges: this.rfpService.isEditable() && !this.gridLoadingData && (this.nonPremiumServiceAccessorialCount != this.gridApi?.getSelectedRows().length),
      tabName: "Accessorials", 
      matBadge: this.nonPremiumServiceAccessorialCount
    };
  }

  onRfpSave(): Observable<SaveEvent> {
    let saveEvent: SaveEvent  = {
      changes: []
    }

    const selectedAccessorials = this.gridApi.getSelectedRows().map((x: RfpAccessorial)=> {
      return {
        accessorialCode: x.accessorialCode,
        accessorialName: x.accessorialName,
        amount: x.amount,
        isWaived: x.isWaived,
        unitOfMeasure: x.unitOfMeasure
      }
    }) 
    saveEvent.changes.push({ key: "accessorials", value: selectedAccessorials })
    return of(saveEvent);
  }

  onRfpSaveSuccess(): void { }

  handleEditability(): void {
    if(this.rfpService.isEditable() && !this.isEditing){
      this.onStartEditing();
    }
    else if(!this.rfpService.isEditable() && this.isEditing){
      this.onCancelEditing();
    }
    this.handlePremiumServices();
    const colDefs: ColDef[] = this.gridApi.getColumnDefs();
    this.gridApi.setColumnDefs(colDefs);
  }

  handlePremiumServices(){
    if(this.gridApi){
      let nodesChanged: Set<RowNode> = new Set();

      //Handle disabled Accessorials
      this.premiumServicesStatus.disabledAccessorials.forEach((accessorialCode: string ) => {
        const rowNode: RowNode = this.gridApi.getRowNode(accessorialCode);
        
        if(rowNode){
          nodesChanged.add(rowNode);
          if(rowNode.isSelected() && this.premiumServicesStatus?.hasMustArriveByDate) {
            this.gridApi.deselectNode(rowNode);
            this.snackBar.open({
              message: `${accessorialCode} was de-selected because it is incompatible with the 'Must Arrive By Date' Premium Service.`,
              status: "warn",
              matConfig: {
                duration: 10000
              }
            })
          }
          else {
            rowNode.setData(rowNode.data)
          }
        }
      })

      //Handle Waived Accessorials
      const defaultState = {
        amount: 0,
        isWaived: true
      }
      this.premiumServicesStatus.waivedAccessorials.forEach((accessorialCode: string ) => {
        const rowNode: RowNode = this.gridApi.getRowNode(accessorialCode);
        if(rowNode){
          nodesChanged.add(rowNode);
          if(this.premiumServicesStatus?.hasMustArriveByDate){
            this.gridApi.selectNode(rowNode, true, true);
            rowNode.setData({ ...rowNode.data, ...defaultState })
          }
          else {
            this.gridApi.deselectNode(rowNode, true);
          }
        }
      })

      if(!this.premiumServicesStatus?.hasMustArriveByDate) {
        this.gridApi.forEachNode(node => {
          node.setData(node.data)
        })
      }
    }
  }
}