import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ManagerEmployee, RfpUser } from '@xpo-ltl/sdk-rfpmgmt';
import { ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { GridColumnPartialsHelper } from '../../grid-column-partial-helper';
import { Observable } from 'rxjs';

export enum UserSelectDialogResponseStatus {
  Selected,
  Reset,
  Cancel
}

@Component({
  selector: 'user-select-dialog',
  templateUrl: './user-select-dialog.component.html',
  styleUrls: ['./user-select-dialog.component.scss']
})
export class UserSelectDialogComponent implements OnInit {
  selectedUser: RfpUser;

  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  columnDefs = [
    GridColumnPartialsHelper.RowIndex,
    { field: 'employeeId',        headerName: "Employee ID",  filter: 'agTextColumnFilter', width: 124, hide: true, },
    { field: 'employeeFullName',  headerName: "Name",         filter: 'agTextColumnFilter', },
    { field: 'managerFullName',   headerName: "Manager",      filter: 'agSetColumnFilter', },
    { field: 'roleType',          headerName: "Role",         filter: 'agSetColumnFilter', },
    { field: 'channelCode',       headerName: "Channel",      filter: 'agSetColumnFilter', },
    { field: 'regionCode',        headerName: "Region",       filter: 'agSetColumnFilter', },
  ]

  gridOptions: GridOptions = {
    defaultColDef: {
        resizable: false,
        sortable: true,
        filter: true,
        floatingFilter: true,
    },
    rowSelection: "single",
    columnDefs: this.columnDefs,
    pagination: true,
    getRowNodeId: (data) => data.employeeId,
    onSelectionChanged: this.onSelectionChanged.bind(this),
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: Observable<RfpUser[]>, public dialogRef: MatDialogRef<UserSelectDialogComponent>) {
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.data?.subscribe(users => {
      this.gridApi.setRowData(users)
      this.gridApi.sizeColumnsToFit();
    })
  }

  ngOnInit(): void {
  }

  onSelectionChanged() {
    this.selectedUser = this.gridApi.getSelectedRows()[0];
  }

  selectUser(){
    this.dialogRef.close({ status: UserSelectDialogResponseStatus.Selected, user: this.selectedUser });
  }

  reset(){
    this.dialogRef.close({ status: UserSelectDialogResponseStatus.Reset });
  }

  cancel(){
    this.dialogRef.close({ status: UserSelectDialogResponseStatus.Cancel });
  }
}
