import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { IconButtonCellRendererComponent } from "src/app/_reusable/cell-renderers/icon-button-cell-renderer/icon-button-cell-renderer.component";
import { GridColumnPartialsHelper } from "src/app/_reusable/grid-column-partial-helper";
import { BehaviorSubject, Observable, forkJoin, map } from 'rxjs';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { RfpMgmtApiService, GetAllRfpsListQuery, GetAllRfpsListResp, RfpUser } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpDashboardSummary } from 'src/app/_models/rfp/rfpDashboardSummary';
import { LookupService } from 'src/app/_services/lookup.service';
import { UrlService } from 'src/app/_services/url.service';
import { UserService } from 'src/app/_services/user.service';
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserSelectDialogComponent, UserSelectDialogResponseStatus } from 'src/app/_reusable/dialogs/user-select-dialog/user-select-dialog.component';
import { UserSelectDialogResponse } from 'src/app/_reusable/dialogs/user-select-dialog/user-select-dialog-response';
import { XpoTileModel } from '@xpo-ltl/ngx-ltl-core/tile-ribbon';

@Component({
  selector: 'my-rfps',
  templateUrl: './my-rfps.component.html',
  styleUrls: ['./my-rfps.component.scss']
})
export class MyRfpsComponent implements OnInit, AfterContentChecked {
  currentUser: RfpUser;
  userUpdated: BehaviorSubject<RfpUser>;
  
  employeeList: RfpUser[] = [];
  tiles: XpoTileModel[] = [];
  
  columnDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.RowIndex,
    { field: 'openRfp',                   headerName: '',   cellRendererFramework: IconButtonCellRendererComponent, cellRendererParams: { onClickCallback: this.navigateToRfp.bind(this), tooltip: "Open RFP", iconName:"edit" }, ...GridColumnPartialsHelper.XpoStatusColDef,  },
    { field: 'openRfpNewTab',             headerName: '',   cellRendererFramework: IconButtonCellRendererComponent, cellRendererParams: { onClickCallback: this.openRfpInNewWindow.bind(this), tooltip: "Open RFP In New Tab", iconName:"open-in-new" }, ...GridColumnPartialsHelper.XpoStatusColDef,  },
    { field: "salesRfpId",                headerName: "Rfp ID",               filter: 'agTextColumnFilter', },
    { field: "madCode",                   headerName: "MAD Code",             filter: 'agTextColumnFilter', },
    { field: "accountName",               headerName: "Account Name",         filter: 'agTextColumnFilter', },
    { field: "rfpType",                   headerName: "Rfp Type",             filter: 'agSetColumnFilter', },
    { field: "salesProgram",              headerName: "Sales Pgm",            filter: 'agSetColumnFilter', },
    { field: "status",                    headerName: "Status",               filter: 'agSetColumnFilter', },
    { field: 'daysSinceLastStatusUpdate',	headerName: "Days In Queue",        filter: 'agNumberColumnFilter', },
    { field: "reasonCode",                headerName: "Return Reason",        filter: 'agSetColumnFilter', },
    { field: "creatorFullName",           headerName: "Creator",              filter: 'agTextColumnFilter', }, 
    { field: "salesAgentFullName",        headerName: "Sales Agent",          filter: 'agTextColumnFilter', },
    { field: "pricingAnalystFullName",    headerName: "Pricing Analyst",      filter: 'agTextColumnFilter', },
    { field: "requestDate",               headerName: "Request Date",         ...GridColumnPartialsHelper.DateCell(), sort: "desc", sortIndex: 1 },
    { field: "dueDate",                   headerName: "Due Date",             ...GridColumnPartialsHelper.DateCell(), sort: "desc", sortIndex: 2 },
    { field: "renegotiationDate",         headerName: "Renegotiation Date",   ...GridColumnPartialsHelper.DateCell(), },
    { field: "isActive",                  headerName: "Is Active",            ...GridColumnPartialsHelper.BooleanCell(), sort: "desc", sortIndex: 0, maxWidth: 118 },
    { field: "isDpt",                     headerName: "Is DPT",               ...GridColumnPartialsHelper.BooleanCell(), maxWidth: 91 },
  ]

  rfpGridApi: GridApi;
  gridLoadingData: boolean = false;

  gridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
    },
    columnDefs: this.columnDefs,
    getRowNodeId: (data) => data.salesRfpId,
  }

  constructor(private rfpApi: RfpMgmtApiService, public lookup: LookupService, private router: Router, private dialog: MatDialog, private snackBar: XpoSnackBar, private changeDetector: ChangeDetectorRef, private urlService: UrlService, private userService: UserService) { }
  
  ngOnInit(): void {
    this.userService.rfpUser$.subscribe((resp) => {
      this.currentUser = resp;
      this.userUpdated = new BehaviorSubject(resp);
      
      this.userUpdated.subscribe(x => {
        this.getGridData(x)
      })
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onGridReady(event: GridReadyEvent): void {
    this.rfpGridApi = event.api
    this.loadPremiumServices();
    this.rfpGridApi.showLoadingOverlay();
  } 

  getGridData(employee: RfpUser) {
    let queryParams = new GetAllRfpsListQuery();
    queryParams.excludeDpt = false;
    queryParams.excludeNonDpt = false;

    this.gridLoadingData = true;
    this.rfpGridApi?.showLoadingOverlay();

    const requests = {
      isManager: this.userService.isUserManager(),
      rfps: this.rfpApi.getAllRfpsList({employeeId: employee.employeeId}, queryParams),
      rfpTypes: this.lookup.rfpTypes$,
      rfpStatuses: this.lookup.rfpStatuses$,
      salesPrograms: this.lookup.salesPrograms$,
      salesReturnReasons: this.lookup.salesReturnReasons$,
      pricingReturnReasons: this.lookup.pricingReturnReasons$
    }
    
    forkJoin(requests).subscribe({
      next: results => {
        const isManager: boolean = results.isManager;
        const rfps: RfpDashboardSummary[] = results.rfps.items;
        const rfpTypes: LookupCodeNamePair[] = results.rfpTypes;
        const rfpStatuses: LookupCodeNamePair[] = results.rfpStatuses;
        const salesPrograms: LookupCodeNamePair[] = results.salesPrograms;
        const salesReturnReasons: LookupCodeNamePair[] = results.salesReturnReasons;
        const pricingReturnReasons: LookupCodeNamePair[] = results.pricingReturnReasons;

        this.rfpGridApi.setRowData(rfps.map((rfpSummary: RfpDashboardSummary) => {
          rfpSummary.premiumServiceTypeCodes?.forEach(code => {
            rfpSummary[code] = true;
          });

          return { 
            ...rfpSummary, 
            rfpType: rfpTypes.find(x => x.code?.toLocaleLowerCase() === rfpSummary.rfpType?.toLocaleLowerCase())?.name ?? rfpSummary.rfpType,
            status: rfpStatuses.find(x => x.code?.toLocaleLowerCase() === rfpSummary.status?.toLocaleLowerCase())?.name ?? rfpSummary.status,
            salesProgram: salesPrograms.find(x => x.code?.toLocaleLowerCase() === rfpSummary.salesProgram?.toLocaleLowerCase())?.name ?? rfpSummary.salesProgram,
            reasonCode: this.getReasonCodeName(rfpSummary.reasonCode, salesReturnReasons, pricingReturnReasons),
          }
        }))

        this.createTileHeader(rfps, employee.employeeFullName, isManager)
      },
      error: err => {
        this.snackBar.open({
          message: `There was an issue retreiving list of sales rfps for ${this.currentUser.employeeId}`,
          detailedMessage: "Please try again.",
          status: "error"
        })
        this.rfpGridApi.setRowData(null)
      },
      complete: () => this.gridLoadingData = false
    });
  }

  loadPremiumServices() {
    this.lookup.premiumServices$.subscribe({
      next: (premiumServices) => {
        const premiumServiceColumns = premiumServices
          .sort((a, b) => a.premiumServiceTypeCode.localeCompare(b.premiumServiceTypeCode))
          .map((service) => {
            return {
              headerName: service.premiumServiceTypeCode,
              field: service.premiumServiceTypeCode,
              ...GridColumnPartialsHelper.BooleanCell(true, false, true, false),
              width: 100,
              columnGroupShow: service.premiumServiceTypeCode !== "MABD" ? "open" : undefined
            } as ColDef;
          });

        const premiumServiceGroup: ColGroupDef = {
          headerName: 'Premium Services',
          children: premiumServiceColumns,
        };

        // Insert the "Premium Services" group after the "RFP Type" column
        const rfpInfoGroupIndex = this.columnDefs.findIndex(
          (colDef) => (colDef as ColDef).field === 'rfpType'
        );
        if (rfpInfoGroupIndex !== -1) {
          this.columnDefs.splice(rfpInfoGroupIndex + 1, 0, premiumServiceGroup);
        } else {
          this.columnDefs.push(premiumServiceGroup); // Fallback, put it at the end if "rfpType" is not found
        }

        if (this.rfpGridApi) {
          this.rfpGridApi.setColumnDefs(this.columnDefs);
        }
      }
    });
  }

  /* Try and find the given reason code first in Sales Reasons, if not there then Pricing Reasons, if not there then just return the original code. */
  getReasonCodeName(code: string, salesReasons: LookupCodeNamePair[], pricingReasons: LookupCodeNamePair[]){
    return salesReasons.find(x => x.code === code)?.name ?? pricingReasons.find(x => x.code === code)?.name ?? code;
  }

  createTileHeader(rfps: RfpDashboardSummary[], employeeName: string, isManager: boolean){
    this.tiles = [
      {
        title: {
          text: employeeName,
        },
        link: isManager ? "Change" : undefined,
        label: {
          text: "Current Employee",
        }
      },
      {
        title: {
          text: `${rfps.filter(x => x.isActive).length}`,
        },
        label: {
          text: "Active",
        }
      },
      {
        title: {
          text: `${rfps.filter(x => x.status == "InSales" || x.status == "New" || x.status == "InRdsReview").length}`,
        },
        label: {
          text: "With Sales",
        }
      },
      {
        title: {
          text: `${rfps.filter(x => x.status == "InPricing" || x.status == "Assigned" || x.status == "Submitted" || x.status == "DptReview").length}`,
        },
        label: {
          text: "With Pricing",
        }
      },
      {
        title: {
          text: `${rfps.filter(x => x.isDpt).length}`,
        },
        label: {
          text: "Contracted Pricing",
        }
      },
      {
        title: {
          text: `${rfps.filter(x => !x.isDpt).length}`,
        },
        label: {
          text: "Dynamic Pricing",
        }
      },
    ]
  }

  linkClick(tile: XpoTileModel): void {
    this.openUserSelectDialog();
  }

  navigateToRfp(rowNode: RowNode){
    const rfpId = rowNode.id
    this.urlService.isInternalRouting = true;
    const url = `/rfp/${rfpId}`;
    this.router.navigateByUrl(url)
  }

  openRfpInNewWindow(rowNode: RowNode){
    const rfpId = rowNode.id
    const url = `/rfp/${rfpId}`;
    window.open(url, '_blank');
  }

  openUserSelectDialog(){
    this.dialog.open(UserSelectDialogComponent, { width: "1100px", height: "874px", data: this.rfpApi.listUsers({} as any).pipe(map(x => x.items)) }).afterClosed().subscribe((response: UserSelectDialogResponse)  => {
      if(response && response?.status != UserSelectDialogResponseStatus.Cancel){
        this.userUpdated.next(response.user ?? this.currentUser)
      }      
    })
  }
}