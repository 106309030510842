import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LookupService } from 'src/app/_services/lookup.service';
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';
import { UserService } from 'src/app/_services/user.service';
import { RfpUser } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';


export enum SubmitToPricingFormFields {
  ReturnReason = "returnReason"
}

export interface SubmitToPricingDialogResponse {
  returnReason: string;
}

@Component({
  selector: 'submit-to-pricing-dialog',
  templateUrl: './submit-to-pricing-dialog.component.html',
  styleUrls: ['./submit-to-pricing-dialog.component.scss']
})
export class SubmitToPricingDialogComponent implements OnInit {

  submitToPricingFormFields = SubmitToPricingFormFields;
  submitToPricingForm: UntypedFormGroup;
  userIsNationalSalesSpecialist: boolean = false;
  salesReturnReasons: LookupCodeNamePair[];
  excludedSalesReasonCodes: string[] = ["AutoClosedIncycleRfp", "AutoClosedDynamicRfp"];

  constructor(@Inject(MAT_DIALOG_DATA) public data: RfpDetails, public dialogRef: MatDialogRef<SubmitToPricingDialogComponent, SubmitToPricingDialogResponse>, public lookup: LookupService, private userService: UserService, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.createForm();
    this.userService.rfpUser$.subscribe((resp) => {
      this.userIsNationalSalesSpecialist = resp?.roleType === 'National Account Sales Specialist';
    });

    this.lookup.salesReturnReasons$.subscribe((values) => {
      this.salesReturnReasons = values.filter(x => !this.excludedSalesReasonCodes.includes(x.code));
    });
  }

  createForm(){
    this.submitToPricingForm = this.fb.group({
      [SubmitToPricingFormFields.ReturnReason]:  [null, Validators.required],
    })
  }

  submit(){
    this.dialogRef.close(this.submitToPricingForm.value)
  }

  disableReasonCode(reasonCode: LookupCodeNamePair){
    const isThreePlNationalRfp = this.data.isThirdPartyLogistics && this.data.pricingType.toLowerCase() === 'national';

    switch(reasonCode.code){
      // if the RFP is 3PL National, and the user submitting to pricing isn't a NASS disable the publish reason code
      case "Publish":
        return !this.userIsNationalSalesSpecialist && isThreePlNationalRfp ? true : false
      default: 
        return false;
    }
  }

  getOptionTooltip(reasonCode: LookupCodeNamePair){
    if(this.disableReasonCode(reasonCode)){
      return "This is a NASS-only reason code"
    }
    return null;
  }
}
