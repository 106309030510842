<div xpoDialog>
    <h1 mat-dialog-title>
        Edit User - {{selectedUser?.employeeFullName}}
        <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
  
    <div mat-dialog-content>
        <form [formGroup]="userEditForm" class="form-container">
            <span class="header-container">
                <h2>OneCRM Fields</h2>
                <mat-slide-toggle [formControlName]="userEditFormFields.OneCrmOverride" style="margin-left:auto" *ngIf="currentUserIsAdmin">Override</mat-slide-toggle>
            </span>
            <mat-form-field floatLabel="always" class="width-double">
                <mat-label>Manager</mat-label>
                <input type="text" matInput placeholder="Select A New Manager" [formControlName]="userEditFormFields.Manager" [matAutocomplete]="managerAutocomplete" xpoAutocomplete />
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete autoActiveFirstOption #managerAutocomplete [displayWith]="userFieldDisplay">
                    <mat-option *ngFor="let manager of filteredManagerOptions$ | async" [value]="manager">
                        <xpo-select-two-columns-container style="grid-template-columns: 40% 50%;">
                            <span>{{ manager.employeeFullName | titlecase }}</span>
                            <span>{{ manager.employeeId }}</span>
                        </xpo-select-two-columns-container>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <div class="horizontal-container">
                <mat-form-field floatLabel="always">
                    <mat-label>Role</mat-label>
                    <input matInput [formControlName]="userEditFormFields.RoleType"  />
                </mat-form-field>

                <mat-form-field floatLabel="always">
                    <mat-label>Channel</mat-label>
                    <input matInput [formControlName]="userEditFormFields.ChannelCode"  />
                </mat-form-field>
            </div>

            <mat-form-field floatLabel="always">
                <mat-label>Region</mat-label>
                <input matInput [formControlName]="userEditFormFields.RegionCode"  />
            </mat-form-field>


            <div class="horizontal-container">
                <mat-form-field floatLabel="always" xpoDatePicker>
                    <mat-label>Effective Date</mat-label>
                    <input matInput [matDatepicker]="effectiveDatePicker" [formControlName]="userEditFormFields.EffectiveDate" placeholder="MM/DD/YYYY"/>
                    <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"><mat-icon>calendar_today</mat-icon></mat-datepicker-toggle>
                    <mat-datepicker #effectiveDatePicker></mat-datepicker>
                </mat-form-field>
          
                <mat-form-field floatLabel="always" xpoDatePicker>
                    <mat-label>Expiration Date</mat-label>
                    <input matInput [matDatepicker]="expirationDatePicker" [formControlName]="userEditFormFields.ExpiryDate" placeholder="MM/DD/YYYY"/>
                    <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"><mat-icon>calendar_today</mat-icon></mat-datepicker-toggle>
                    <mat-datepicker #expirationDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            
            <div class="divider-horizontal"></div>
            <h2>Sales RFP Fields</h2>

            <div class="horizontal-container">
  
                <div class="vertical-container flex-gap-4">
                    <mat-label>User Options</mat-label>
                    <mat-slide-toggle [formControlName]="userEditFormFields.IsRds">RDS</mat-slide-toggle>
                    <mat-slide-toggle [formControlName]="userEditFormFields.IsManager">Manager</mat-slide-toggle>
                    <mat-slide-toggle [formControlName]="userEditFormFields.ManageUserRdsReview" *ngIf="currentUserIsAdmin">Manage User RDS Review</mat-slide-toggle>
                    <mat-slide-toggle [formControlName]="userEditFormFields.IsAdmin" *ngIf="currentUserIsAdmin">Admin</mat-slide-toggle>
                </div>
                
                <div class="vertical-container flex-gap-4">
                    <mat-label>Application Options</mat-label>
                    <mat-slide-toggle [formControlName]="userEditFormFields.EnablePremiumServices">Enable Premium Services</mat-slide-toggle>
                    <mat-slide-toggle [formControlName]="userEditFormFields.RequireRdsReview" *ngIf="currentUserIsRdsManager || currentUserIsAdmin">Require RDS Review</mat-slide-toggle>
                    <mat-slide-toggle [formControlName]="userEditFormFields.CannotModifyRfp" *ngIf="currentUserIsAdmin">Cannot Modify RFPs</mat-slide-toggle>
                    <mat-slide-toggle [formControlName]="userEditFormFields.CreateRfp" *ngIf="currentUserIsAdmin">Create RFP</mat-slide-toggle>
                </div>
            </div>

        </form>
    </div>
  
    <div mat-dialog-actions>
        <button mat-flat-button xpoLargeButton xpoButton [disabled]="userEditForm.pristine || userEditForm.invalid" (click)="submit()">Submit</button>
    </div>
</div>