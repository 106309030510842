<div xpoDialog>
    <h1 mat-dialog-title>
      Submit to Pricing
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
  
    <div mat-dialog-content>
        <form [formGroup]="submitToPricingForm">
            <mat-form-field floatLabel="always">
              <mat-label>Return Reason</mat-label>
              <mat-select [formControlName]="submitToPricingFormFields.ReturnReason" required placeholder="Select return reason" xpoSelect>
                <mat-option *ngFor="let returnReason of salesReturnReasons" [value]="returnReason.code" [disabled]="disableReasonCode(returnReason)" [matTooltip]="getOptionTooltip(returnReason)" matTooltipPosition="before"> {{ returnReason.name }} </mat-option>
              </mat-select>
            </mat-form-field>
        </form>
    </div>
  
    <div mat-dialog-actions>
        <xpo-button-group>
            <button mat-stroked-button xpoButton (click)="dialogRef.close()">Cancel</button>
        </xpo-button-group>
        
        <xpo-button-group>    
            <button mat-flat-button xpoButton [disabled]="submitToPricingForm.invalid" (click)="submit()">Submit</button>
        </xpo-button-group>
    </div>
  </div>