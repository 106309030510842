import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { OnSaveEvent, SaveEvent, TabStatus } from '../rfp-edit.component';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin, of } from 'rxjs';
import { RfpMgmtApiService, RfpMgmtContactDetails, UpdateRfpsStatusRqst } from '@xpo-ltl/sdk-rfpmgmt';
import { AccountOverview, RfpContact } from 'src/app/_models/temp/rfpAccount';
import { RfpDocumentsComponent } from '../components/forms/rfp-documents/rfp-documents.component';
import { ContextualHeaderActions, ContextualHeaderItems } from '@xpo-ltl/ngx-ltl-core/contextual-header';
import { XpoTileModel } from '@xpo-ltl/ngx-ltl-core/tile-ribbon';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { LookupService } from 'src/app/_services/lookup.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { UserService } from 'src/app/_services/user.service';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/_services/utility.service';
import { MetricFormat } from 'src/app/_models/enums';
import { CustomerInfoDialogInput } from '../components/dialogs/customer-info-dialog/customer-info-dialog-input';
import { CustomerInfoDialogComponent } from '../components/dialogs/customer-info-dialog/customer-info-dialog.component';
import { SubscriptionDialogInput } from '../components/dialogs/subscription-dialog/subscription-dialog-input';
import { SubscriptionDialogComponent } from '../components/dialogs/subscription-dialog/subscription-dialog.component';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { RfpService } from 'src/app/_services/rfp.service';

export enum RfpCancelPricingFormFields {
  ProposedExpirationDate = "proposedExpirationDate",
}

export enum RfpContactFormFields {
  ContactName = "contactName",
  AddressLine1 = "addressLine1",
  AddressLine2 = "addressLine2",
  CityName = "cityName",
  StateCode = "stateCode",
  ZipCode = "zipCode",
  CountryCode = "countryCode",
  ContactEmail = "contactEmail",
  ContactFaxNumber = "contactFaxNumber"
}

@Component({
  selector: 'rfp-cancel-pricing',
  templateUrl: './rfp-cancel-pricing.component.html',
  styleUrls: ['./rfp-cancel-pricing.component.scss']
})
export class RfpCancelPricingComponent implements OnInit {
  @Input() rfpDetails: RfpDetails;
  @Input() accountOverview: AccountOverview;
  @Input() rfpContact: RfpContact;
  @ViewChild(RfpDocumentsComponent) documentComponent!: RfpDocumentsComponent;
  @Output() refreshRfpDetails = new EventEmitter<number>();
  
  unsavedRfpDetails: any = {};
  selectedTabIndex: number = 0;

  savingRfpData: boolean = false;
  makingApiCall: boolean = false;

  contextualHeaderItems: ContextualHeaderItems[] = [];
  actionsConfig: ContextualHeaderActions = { }; 

  rfpCancelPricingForm: UntypedFormGroup;
  rfpCancelPricingFormFields = RfpCancelPricingFormFields;
  rfpContactFormFields = RfpContactFormFields;

  constructor(
    private snackbar: XpoSnackBar, 
    private formBuilder: UntypedFormBuilder, 
    private rfpApi: RfpMgmtApiService, 
    private lookup: LookupService, 
    private dialog: MatDialog, 
    private confirmDialog: XpoConfirmDialog, 
    private userService: UserService, 
    private route: ActivatedRoute,
    protected rfpService: RfpService,
  ) {}

  ngOnInit(): void {
    if(this.route.snapshot.queryParams.unsubscribe){
      this.openSubscriptionsDialog(this.route.snapshot.queryParams.unsubscribe);
    }
    this.createContextualHeader();

    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges){
    if((changes.rfpDetails && !changes.rfpDetails.firstChange) ||
       (changes.rfpContact && !changes.rfpContact.firstChange)
    ){
      this.createContextualHeader();
      this.createForm();
    }
  }

  createForm(){
    this.rfpCancelPricingForm = this.formBuilder.group(
      {
        [RfpCancelPricingFormFields.ProposedExpirationDate]:  [this.rfpDetails?.proposedExpirationDate, [Validators.required]],
        [RfpContactFormFields.ContactName]:                   [this.rfpContact?.contactName, [Validators.required]],
        [RfpContactFormFields.AddressLine1]:                  [this.rfpContact?.addressLine1, [Validators.required]],
        [RfpContactFormFields.AddressLine2]:                  [this.rfpContact?.addressLine2],
        [RfpContactFormFields.CityName]:                      [this.rfpContact?.cityName, [Validators.required]],
        [RfpContactFormFields.StateCode]:                     [this.rfpContact?.stateCode, [Validators.required, Validators.maxLength(3)]],
        [RfpContactFormFields.ZipCode]:                       [this.rfpContact?.zipCode, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
        [RfpContactFormFields.CountryCode]:                   [this.rfpContact?.countryCode, [Validators.required, Validators.maxLength(2)]],
        [RfpContactFormFields.ContactEmail]:                  [this.rfpContact?.contactEmail, [Validators.email]],
        [RfpContactFormFields.ContactFaxNumber]:              [this.rfpContact?.contactFaxNumber],
      }
    )

    setTimeout(() => {
      this.handleEditability();
    }, 0);
  }

  createContextualHeader() {
		forkJoin([this.lookup.rfpTypes$, this.lookup.rfpStatuses$, this.lookup.threePLTypes$]).subscribe(response => {
			const rfpTypes = response[0];
			const rfpStatuses = response[1];

      this.contextualHeaderItems = [
        {
          labelTitle: 'RFP ID',
          title: `${this.rfpDetails.salesRfpId}`,
        },
        {
          labelTitle: 'RFP Type',
          title: rfpTypes.find(x => x.code.toLocaleLowerCase() === this.rfpDetails.rfpTypeCode.toLocaleLowerCase())?.name,
        },
        {
          labelTitle: 'Status',
          title: rfpStatuses.find(x => x.code.toLocaleLowerCase() === this.rfpDetails.rfpStatusCode.toLocaleLowerCase())?.name
        },
        {
          labelTitle: 'Account',
          title: `${this.rfpDetails.madCode}`,
          action: {
            action: {
              icon: 'visibility',
              itemActionFn: this.openCustomerInfoDialog.bind(this),
            },
          },
        },
        {
          labelTitle: 'Agreement',
          title: `${this.rfpDetails.pricingAgreementId}`,
        },
        {
          labelTitle: 'Requested Date',
          title: UtilityService.formatDate(this.rfpDetails.requestedDate),
        },
      ]

      this.actionsConfig = {
        actionsList: [
          {
						name: 'RFP Notifications',
						icon: 'notifications',
						actionFn: this.openSubscriptionsDialog.bind(this),
					},
          {
            name: 'Refresh Rfp Details',
            icon: 'refresh',
            actionFn: this.callRefreshRfpDetails.bind(this),
          },
        ]
      }
    })
  }

  callRefreshRfpDetails(){
    this.refreshRfpDetails.emit(this.rfpDetails.salesRfpId)
  }

  callSaveRfpData(){
		this.saveRfpData().subscribe(saveSuccessful => {
			if(saveSuccessful) {
				this.snackbar.success("Save Successful")
			}
			else {
				this.snackbar.error("Something happened while saving, please try again")
			}
		})
	}

  getTabStatus(): TabStatus {
    return { 
      complete: this.rfpCancelPricingForm?.enabled ? this.rfpCancelPricingForm?.valid : true, 
      canSave: true,
      unsavedChanges: this.rfpCancelPricingForm?.dirty,
      tabName: "Cancel Pricing Details"
    };
  }

  onSave(): Observable<SaveEvent> {
    let saveEvent: SaveEvent  = {
      changes: []
    }
    saveEvent.changes.push({ key: RfpCancelPricingFormFields.ProposedExpirationDate,  value: this.rfpCancelPricingForm.get(RfpCancelPricingFormFields.ProposedExpirationDate).value })

    const filteredFormValue = Object.entries(this.rfpCancelPricingForm.value)
    .reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    this.rfpApi.saveRfpContact(filteredFormValue as any, {rfpId: this.rfpDetails.salesRfpId}).subscribe()

    return of(saveEvent);
  }

  saveRfpData(): Observable<boolean> {
    const canSaveResp = [this.getTabStatus()]

		if (this.rfpCancelPricingForm.valid) {
			const saveObservables = [this.onSave()];

			return forkJoin(saveObservables).pipe(
			  tap((saveEvents: SaveEvent[]) => {
          saveEvents.forEach(saveEvent => {
            this.unsavedRfpDetails = { ...this.unsavedRfpDetails, ...saveEvent.changes.reduce((obj, change) => ({ ...obj, [change.key]: change.value }), {}) };
          });
			  }),
			  tap(() => this.savingRfpData = true),
			  switchMap(() => {
				const updatedRfp = new RfpDetails({ ...this.rfpDetails, ...this.unsavedRfpDetails });
				
				return this.rfpApi.upsertRfp(updatedRfp).pipe(
				  map(() => {
            this.rfpDetails = updatedRfp;
              this.onSaveSuccess();
            return true;
				  }),
				  catchError((error) => {
					  return of(false);
				  }),
          finalize(() => this.savingRfpData = false)
				)
			  }),
			);
		  }
    else {
      this.snackbar.open({
        message: 'Cannot Save, Please See Following Tabs:',
        detailedMessage: canSaveResp.filter(x => !(x.canSave ?? true)).map(x => x.tabName).join(", "),
        status: 'error',
        matConfig: {
          duration: 0,
        },
      })
      return of(false);
    }
  }
  
  onSaveSuccess(): void {
    this.rfpCancelPricingForm.markAsPristine();
    this.handleEditability();
  }
  

  handleEditability(): void {
    this.rfpService.isEditable() ? this.rfpCancelPricingForm.enable() : this.rfpCancelPricingForm.disable();
  }

  navigateToDocumentTab(docId: number){
    this.selectedTabIndex = 5
    this.documentComponent.filterDocById(docId)
  }

  canSubmitToPricing(): boolean {
		return this.rfpCancelPricingForm.valid && 
      this.rfpService.isEditable() && 
      !this.makingApiCall
	}

  getSubmitToPricingError(): string {
    if(this.rfpCancelPricingForm.invalid) return "All forms must be complete before submitting"
    if(!this.rfpService.isEditable()) return "You cannot currently edit this RFP"
    if(this.makingApiCall) return "Currently submitting this RFP"
    return null
  }

  submitToPricing(){
    this.makingApiCall = true;
    this.lookup.rfpStatuses$.subscribe((response) => {
      var submittedStatus = response.find(t => t.code.toLocaleLowerCase().localeCompare("submitted".toLocaleLowerCase()) === 0)?.code;

      this.saveRfpData().subscribe((saveSuccessful: boolean) => {
        if(!saveSuccessful) {
          this.makingApiCall = false;
          return;
        }

        
        let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
          statusCode: submittedStatus,
          rfpIds: [this.rfpDetails.salesRfpId]
        };

        this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
          () => {
            this.snackbar.success("Successfuly submitted RFP to Pricing")
          },
          (error) => {
            this.snackbar.error("Something happened while submitting to pricing, please try again")
          },
          () => {
            this.callRefreshRfpDetails();
          }
        ).add(() => {
          this.makingApiCall = false;
        })
      })
    });
  }

  closeRfp(){
    const confirmConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'Close RFP',
      rejectButtonText: 'Cancel',
      icon: 'warning',
    };

    this.confirmDialog.confirm(
      '',
      'Are you sure you want to close this RFP?',
      confirmConfig
    ).subscribe((result) => {
      if(result){
        this.makingApiCall = true;
        this.lookup.rfpStatuses$.subscribe((response) => {
          var closeStatusCd = response.find(status => status.code.toLocaleLowerCase() === "close".toLocaleLowerCase())?.code;
            
          let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
            statusCode: closeStatusCd,
            rfpIds: [this.rfpDetails.salesRfpId]
          };
    
          this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
            () => {
              this.snackbar.success("Successfuly Closed RFP")
            },
            (error) => {
              this.snackbar.error("Something happened while trying to close this RFP, please try again")
            },
            () => {
              this.callRefreshRfpDetails();
            }
          ).add(() => {
            this.makingApiCall = false;
          })
        });

      }
    });
  }

  openCustomerInfoDialog(){
    const dialogData: CustomerInfoDialogInput = {
      accountOverview: this.accountOverview,
      rfpDetails: this.rfpDetails,
      rfpContact: this.rfpContact,
      reviews: [],
    }

    this.dialog.open(CustomerInfoDialogComponent, { data: dialogData, position: { top: "10vh" } }).afterClosed().subscribe(refresh => {
      if (refresh) {
        this.callRefreshRfpDetails();
      }
    });
  }

  openSubscriptionsDialog(unsubscribeCode?: string){
    this.userService.authUser$.subscribe(user => {
      const dialogData: SubscriptionDialogInput = {
        salesRfpId: this.rfpDetails.salesRfpId,
        userEmailAddress: user.emailAddress,
        unsubscribeCode: unsubscribeCode ?? undefined
      }
  
      this.dialog.open(SubscriptionDialogComponent, {data: dialogData, position: { top: "10vh" }});
    })
  }
}
