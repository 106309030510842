import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { AccountAgreement, CreateRfpResp, CreateRfpRqst, GetAccountAgreementsResp, ListUsersResp, ListUsersRqst, RfpMgmtApiService, RfpMgmtPremiumServiceV2LookupPair, RfpMgmtPremiumServiceQuestionnaire, RfpPrimaryContact, RfpUser, SalesRfpHeader, SkeletonRfpAdditionalFields, GetAccountOverviewResp } from '@xpo-ltl/sdk-rfpmgmt';
import { forkJoin } from 'rxjs';
import { AccountOverview } from 'src/app/_models/temp/rfpAccount';
import { LookupService } from 'src/app/_services/lookup.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigSettings } from 'src/app/_models/configSettings';
import { CurrencyPipe } from "@angular/common";
import { RdsEmployeeLookupPair, LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';
import { RfpMgmtUser } from 'src/app/_models/lookups/rfpMgmtUser';
import { faker, fakerEN_US, fakerES_MX } from '@faker-js/faker';
import { RfpConfigService, RfpMgmtConfig } from 'src/app/_services/rfp.config.service';

enum RfpCreateFormGroupFields {
  NegotiatingCustMadCd = "negotiatingCust",
  PrimaryAcctMadCd = "primaryAcct",
  PricingTypeCd = "pricingTypeCd",
  RfpTypeCd = "rfpTypeCd",
  RevenuePromise = "revenuePromise",
  RevenuePotential = "revenuePotential",
  ShipmentFileInd = "shipmentFileInd",
  InCycleInd = "inCycleInd",
  ThirdPartyLogisticsInd = "thirdPartyLogisticsInd",
  ThirdPartyLogisticsForm = "thirdPartyLogisticsForm",
  ThirdPartyLogisticsTypeCd = "thirdPartyLogisticsTypeCd",
  ThirdPartyLogisticsMadCd = "thirdPartyLogisticsMadCd",
  SalesRegionCd = "salesRegionCd",
  SalesRegionForm = "salesRegionForm",
  RdsEmployeeId = "rdsEmployeeId",
  RetailRolloutInd = "retailRolloutInd",
  AreaCode = "areaCode",
  NassSubmissionInd = "nassSubmissionInd",
  SalesAgentId = "salesAgentId",
  ActiveAgreement = "activeAgreement",
  PremiumServiceInd = "premiumServiceInd",
  PremiumServiceForm = "premiumServiceForm",
  PremiumServiceCodes = "premiumServiceCodes",
  PsaeEmployeeId = "psaeEmployeeId",
  UpdateLocationType = "updateLocationType",
  RequestSicCode = "requestSic"
}

@Component({
  selector: 'rfp-create-dialog',
  templateUrl: './rfp-create-dialog.component.html',
  styleUrls: ['./rfp-create-dialog.component.scss']
})
export class RfpCreateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RfpCreateDialogComponent>, private rfpApi: RfpMgmtApiService, public lookup: LookupService, private formBuilder: UntypedFormBuilder, private snackbar: XpoSnackBar, private config: ConfigManagerService, private rfpConfigService: RfpConfigService) { }

  rfpCreateFormGroupFields = RfpCreateFormGroupFields
  rfpCreateFormGroup: UntypedFormGroup;
  creatingRfp: boolean = false;
  dptThreshold: string = '';
  isRenegotiateRfp: boolean = false;
  isNewOppRfp: boolean = false;
  isLocalRfp: boolean = false;
  isUpdateLocationRfp: boolean = false;
  isCancelPricingRfp: boolean = false;
  isChangeAccessorialRfp: boolean = false;
  negotiatingCustomerOverview: AccountOverview;
  primaryMadCodeOverview: AccountOverview;
  rfpUsers: RfpUser[];
  activeAccountAgreements: AccountAgreement[];
  selectedMadCode: string = '';
  showActiveAgreement: boolean = false;
  isContractedPricing: boolean = false;
  premiumServiceTypes: LookupCodeNamePair[];
  premiumServiceQuestionnaire: RfpMgmtPremiumServiceQuestionnaire[] = [];
  isPremiumService: boolean = false;
  premiumServiceUsers: RfpUser[];
  isProduction: boolean = false;
  testMadCodes: string[] = [];
  rfpTypes: LookupCodeNamePair[];
  dptThresholdAmount: number = 0;
  
  rdsEmployees: RdsEmployeeLookupPair[] = [
    { employeeId: "79098",  employeeName: "Saundra Coyle",       regionCode: "RCF" },
    { employeeId: "69272",  employeeName: "Leticia Trevino",     regionCode: "RC2" },
    { employeeId: "Y5894",  employeeName: "Melissa Mader-Teal",  regionCode: "RCH" },
    { employeeId: "S7739",  employeeName: "Larry Beard",         regionCode: "RCL" },
    { employeeId: "D4965",  employeeName: "Jamie Sheffield",     regionCode: "RCS" },
    { employeeId: "W4715",  employeeName: "Daniel Greer",        regionCode: "RGL" },
    { employeeId: "W1629",  employeeName: "Stephen Trotter",     regionCode: "RG2" },
    { employeeId: "28425",  employeeName: "Brian Gilsrud",       regionCode: "RGP" },
    { employeeId: "G5331",  employeeName: "Greg Mercer",         regionCode: "RGU" },
    { employeeId: "82167",  employeeName: "Christopher Thomas",  regionCode: "RMD" },
    { employeeId: "D6029",  employeeName: "Anthony Borrego",     regionCode: "RM2" },
    { employeeId: "AR795",  employeeName: "Chris Thurman",       regionCode: "RNT" },
    { employeeId: "K2146",  employeeName: "Drew Villastrigo",    regionCode: "RPW" },
    { employeeId: "V8599",  employeeName: "William Simmons",     regionCode: "RSE" },
    { employeeId: "W4976",  employeeName: "Michael Namuth",      regionCode: "RST" },
    { employeeId: "F3534",  employeeName: "William Kennedy",     regionCode: "RTV" },
    { employeeId: "54602",  employeeName: "Michael Pajak",       regionCode: "RWS" },
  ]

  updateLocationTypes: string[] = [
    "Add",
    "Delete"
  ]

  sicCodes: string[] = [
    "IFV",
    "IBU",
    "LBL",
    "XDU",
    "XER"
  ]

  areaCodes: LookupCodeNamePair[] = [
    { code: "CAE",  name: "EASTERN REGION"},
    { code: "CCA",  name: "CENTRAL REGION"},
    { code: "CCX",  name: "CCX"},
    { code: "CEA",  name: "EASTERN REGION"},
    { code: "CGO",  name: "ADMINISTRATION"},
    { code: "CSC",  name: "CSC"},
    { code: "CSE",  name: "CSE"},
    { code: "CSP",  name: "CSP"},
    { code: "CTS",  name: "CTS"},
    { code: "CWA",  name: "WESTERN REGION"},
    { code: "CWX",  name: "CWX"},
    { code: "CWY",  name: "CWY"},
    { code: "RAC",  name: "AREA SALES CENTRAL"},
    { code: "RAE",  name: "AREA SALES EASTERN"},
    { code: "RAW",  name: "AREA SALES WESTERN"},
    { code: "REA",  name: "CLEVELAND SALES REGN"},
    { code: "RHO",  name: "HOUSTON SALES REGION"},
  ]

  regionCodes: LookupCodeNamePair[] = [
    { code: "RCF",  name: "CALIFORNIA 1"},
    { code: "RC2",  name: "CALIFORNIA 2"},
    { code: "RCD",  name: "CANADA"},
    { code: "RCL",  name: "CENTRAL"},
    { code: "RCH",  name: "CHICAGOLAND"},
    { code: "RCS",  name: "COASTAL CAROLINA"},
    { code: "RGL",  name: "GREAT LAKES 1"},
    { code: "RG2",  name: "GREAT LAKES 2"},
    { code: "RGP",  name: "GREAT PLAINS"},
    { code: "RGU",  name: "GULF"},
    { code: "RMD",  name: "MIDWEST 1"},
    { code: "RM2",  name: "MIDWEST 2"},
    { code: "RNT",  name: "NORTHEAST"},
    { code: "RPW",  name: "PACIFIC NW"},
    { code: "RSE",  name: "SOUTHEAST"},
    { code: "RWS",  name: "SOUTHWEST"},
    { code: "RTV",  name: "TENESSEE VALLEY"},
    { code: "RST",  name: "TRI-STATE"},
  ]

  ngOnInit(): void {
    this.isProduction = this.config.getSetting(ConfigSettings.Production);

    const requests = {
      rfpTypes: this.lookup.rfpTypes$,
      premiumServiceTypes: this.lookup.premiumServiceTypes$,
    }

    forkJoin(requests).subscribe({
      next: results => {
        this.rfpTypes = results.rfpTypes;
        this.premiumServiceTypes = results.premiumServiceTypes;
        this.testMadCodes = this.rfpConfigService.getConfigValue(RfpMgmtConfig.TestMadCodes, [])?.split(';');
        this.dptThresholdAmount = this.rfpConfigService.getConfigValue(RfpMgmtConfig.DptThreshold, 0);
        this.dptThreshold = this.formattedDptThreshold(this.dptThresholdAmount);
      },
      error: err => {
        this.snackbar.open({
          message: `There was an issue retreiving list of rfp config field and/or lookup types.`,
          detailedMessage: "Please try again.",
          status: "error"
        })
      }
    });

    this.rfpCreateFormGroup = this.formBuilder.group({
      [RfpCreateFormGroupFields.NegotiatingCustMadCd]:      [null, Validators.required],
      [RfpCreateFormGroupFields.PrimaryAcctMadCd]:          [null, Validators.required],
      [RfpCreateFormGroupFields.PricingTypeCd]:             [null, Validators.required],
      [RfpCreateFormGroupFields.RfpTypeCd]:                 [null, Validators.required],
      [RfpCreateFormGroupFields.RevenuePromise]:            [null, Validators.required],
      [RfpCreateFormGroupFields.RevenuePotential]:          [null, Validators.required],
      [RfpCreateFormGroupFields.ShipmentFileInd]:           [false, Validators.required],
      [RfpCreateFormGroupFields.ThirdPartyLogisticsInd]:    [false, Validators.required],
      [RfpCreateFormGroupFields.InCycleInd]:                [false, Validators.required],
      [RfpCreateFormGroupFields.RetailRolloutInd]:          [false, Validators.required],
      [RfpCreateFormGroupFields.AreaCode]:                  [null, Validators.required],
      [RfpCreateFormGroupFields.NassSubmissionInd]:         [false, Validators.required],
      [RfpCreateFormGroupFields.ThirdPartyLogisticsForm]:   this.createThreePLForm(),
      [RfpCreateFormGroupFields.SalesRegionForm]:           this.createRegionForm(),
      [RfpCreateFormGroupFields.SalesAgentId]:              [null, Validators.required],
      [RfpCreateFormGroupFields.ActiveAgreement]:           null,
      [RfpCreateFormGroupFields.PremiumServiceInd]:          [false, Validators.required],
      [RfpCreateFormGroupFields.PremiumServiceCodes]:        null,
      [RfpCreateFormGroupFields.PsaeEmployeeId]:             null,
      [RfpCreateFormGroupFields.UpdateLocationType]:         null,
      [RfpCreateFormGroupFields.RequestSicCode]:             null,
    })

    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ThirdPartyLogisticsInd).valueChanges.subscribe(newValue => {
      if(newValue){
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ThirdPartyLogisticsForm).enable()
      }
      else {
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ThirdPartyLogisticsForm).disable()
      }
    })

    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PricingTypeCd).valueChanges.subscribe(newValue => {
      if(newValue && newValue === 'Local'){
        this.isLocalRfp = true;
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm).enable();
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesAgentId).setValue(null);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesAgentId).disable();
      }
      else {
        this.isLocalRfp = false;
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm).disable();
        if(this.isChangeAccessorialRfp !== true || this.isCancelPricingRfp !== true){
          this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesAgentId).enable();
        }
      }
      this.getSalesUsers();
    })

    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.NegotiatingCustMadCd).valueChanges.subscribe(newValue => {
      if(newValue && newValue !== '' && newValue !== ' ' && newValue.length >=11){
        if(this.isProduction){
          // if the entered mad code is not allowed test mad codes throw a warning message and
          // stop continuing creating the RFP
           if(!this.testMadCodes.find(f => f.trim() === newValue.trim())){
            this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.NegotiatingCustMadCd).setValue(null);
            this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PrimaryAcctMadCd).setValue(null);
            this.snackbar.warn(`Entered madcode is not in allowed test mad code(s).`);
            return;
           }
        }
        this.selectedMadCode = newValue;
        if(this.isChangeAccessorialRfp || this.isRenegotiateRfp || this.isCancelPricingRfp){
          this.initialiseActiveAgreementFormField();
        }
        this.intialisePricingType(this.selectedMadCode);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PrimaryAcctMadCd).setValue(this.selectedMadCode);
      }
      else {
        this.selectedMadCode = '';
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PricingTypeCd).setValue(null);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PrimaryAcctMadCd).setValue(null);
      }
    })


    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PrimaryAcctMadCd).valueChanges.subscribe(newValue => {
      if(newValue && newValue !== '' && newValue !== ' ' && newValue.length >=11){
        if(this.selectedMadCode !== newValue){
          this.rfpApi.getAccountOverview({madCode: newValue}).subscribe(resp => {
            this.primaryMadCodeOverview = resp;
          })
        }
      }
    })

    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ActiveAgreement).valueChanges.subscribe(agreement => {
      if(agreement !== undefined && agreement !== null){
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesAgentId).setValue(agreement?.salesAgentId);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ShipmentFileInd).setValue(true);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm).get(RfpCreateFormGroupFields.SalesRegionCd).setValue(agreement?.salesRegionCode)
        if(this.isLocalRfp){
          const selectedRds = this.rdsEmployees.filter(f => f.regionCode == agreement?.salesRegionCode)[0];
          this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm).get(RfpCreateFormGroupFields.RdsEmployeeId).setValue(selectedRds?.employeeId)
        }
      }
    })

    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RfpTypeCd).valueChanges.subscribe(newValue => {
      if(newValue && newValue === 'Reneg'){
        this.isRenegotiateRfp = true;
        this.isChangeAccessorialRfp = false;
        this.showActiveAgreement = true;
        this.isCancelPricingRfp = false;
        this.isUpdateLocationRfp = false;
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ActiveAgreement).clearValidators();
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ActiveAgreement).updateValueAndValidity();
        this.initialiseActiveAgreementFormField();
        this.resetChangeAccessorialFormFields(false);
      }
      else if(newValue && newValue === 'NewOpp'){
        this.isRenegotiateRfp = false;
        this.isNewOppRfp = true;
        this.isChangeAccessorialRfp = false; 
        this.isCancelPricingRfp = false;
        this.showActiveAgreement = false;
        this.isUpdateLocationRfp = false;
        this.resetChangeAccessorialFormFields(false);
        this.resetAgreementFields();
      }
      else if(newValue && newValue === 'UpdLoc'){
        this.isRenegotiateRfp = false;
        this.isNewOppRfp = false;
        this.isChangeAccessorialRfp = false; 
        this.isCancelPricingRfp = false;
        this.showActiveAgreement = false;
        this.isUpdateLocationRfp = true;
        this.resetChangeAccessorialFormFields(false);
        this.resetAgreementFields();
      }
      else if(newValue && newValue === 'ChangeAc'){
        this.isNewOppRfp = false;
        this.isRenegotiateRfp = false;
        this.isChangeAccessorialRfp = true;
        this.isCancelPricingRfp = false;
        this.showActiveAgreement = true;
        this.isUpdateLocationRfp = false;
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ActiveAgreement).setValidators(Validators.required);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ActiveAgreement).updateValueAndValidity();
        this.initialiseActiveAgreementFormField();
        this.resetChangeAccessorialFormFields(true);
      }
      else if(newValue && newValue === 'CanPricing'){
        this.isNewOppRfp = false;
        this.isRenegotiateRfp = false;
        this.isChangeAccessorialRfp = false;
        this.isCancelPricingRfp = true;
        this.showActiveAgreement = true;
        this.isUpdateLocationRfp = false;
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ActiveAgreement).setValidators(Validators.required);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ActiveAgreement).updateValueAndValidity();
        this.initialiseActiveAgreementFormField();
        this.resetChangeAccessorialFormFields(true);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RevenuePotential).setValue(0.0);
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RevenuePromise).setValue(0.0);
      }
      else {
        this.isRenegotiateRfp = false;
        this.isChangeAccessorialRfp = false;
        this.isNewOppRfp = false;
        this.isCancelPricingRfp = false;
        this.showActiveAgreement = false;
        this.isUpdateLocationRfp = false;
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ActiveAgreement).disable();
        this.resetChangeAccessorialFormFields(false);
        this.resetAgreementFields();
      }
    })

    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PremiumServiceInd).valueChanges.subscribe(newValue => {
      if(newValue){
        this.isPremiumService = true;
        this.getPreiumServiceUsers();
      }
      else {
        this.isPremiumService = false;
      }
    })
  }

  getThreePlForm(): UntypedFormGroup {
    return this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ThirdPartyLogisticsForm) as UntypedFormGroup
  }

  showThreePlForm(): boolean {
    return this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ThirdPartyLogisticsForm).enabled
  }

  createThreePLForm(){
    const form = this.formBuilder.group({
      [RfpCreateFormGroupFields.ThirdPartyLogisticsTypeCd]: [null, Validators.required],
      [RfpCreateFormGroupFields.ThirdPartyLogisticsMadCd]:  [null, Validators.required],
    })
    form.disable();
    return form;
  }

  getRegionForm(): UntypedFormGroup {
    return this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm) as UntypedFormGroup
  }

  showRegionForm(): boolean {
    return this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm).enabled
  }

  resetChangeAccessorialFormFields(isChangeAccessorialRfp: any) {
    if(isChangeAccessorialRfp){
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RevenuePotential).disable();
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RevenuePromise).disable();
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.AreaCode).disable();
      if(this.isLocalRfp){
        this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesAgentId).disable();
      }
    }
    else{
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesAgentId).enable();
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RevenuePotential).enable();
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RevenuePromise).enable();
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.AreaCode).enable();
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm).get(RfpCreateFormGroupFields.SalesRegionCd).setValue(null)
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm).get(RfpCreateFormGroupFields.RdsEmployeeId).setValue(null)
    }
  }

  resetAgreementFields() {
    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesAgentId).setValue(null);
    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.ShipmentFileInd).setValue(false);
    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.SalesRegionForm).get(RfpCreateFormGroupFields.SalesRegionCd).setValue(null);
    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RevenuePotential).setValue(null);
    this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.RevenuePromise).setValue(null);
  }

  getSalesUsers(){
    const listUserRqst = new ListUsersRqst();
    if(this.isLocalRfp){
      listUserRqst.channelCode = 'Local';
      listUserRqst.excludeRoleTypes = [
        'Director',
        'Divisional Sales Vice President',
        'Director of Sales',
        'Regional Administrator',
        'Senior Vice President',
        'Regional Director of Sales',
        'Regional Vice President',
        'Service Center Manager',
        'Vice President', 
        'Senior Specialist', 
        'Director of Business Development'
      ]; 
    }
    else{
      listUserRqst.channelCode = 'National';
      listUserRqst.excludeRoleTypes = [
        'Divisional Sales Vice President',
        'Senior Analyst',
        'Legacy',
        'Analyst',
        'Director of National Sales',
        'Manager',
        'Director of Premium Services',
        'Director of Business Development',
        'Director of Sales Solutions'
      ]; 
    }
    this.rfpApi.listUsers(listUserRqst).subscribe((resp: ListUsersResp) => {
      this.rfpUsers = resp.items;
    },
    (error) => {
      this.rfpUsers = [];
    })
  }

  getPreiumServiceUsers(){
    const listUserRqst = new ListUsersRqst();
    listUserRqst.includeRoleTypes = ['Premium Services Executive'];
    this.rfpApi.listUsers(listUserRqst).subscribe((resp: ListUsersResp) => {
      this.premiumServiceUsers = resp.items;
    },
    (error) => {
      this.premiumServiceUsers = [];
    })
  }

  createRegionForm(){
    const form = this.formBuilder.group({
      [RfpCreateFormGroupFields.SalesRegionCd]: [null, Validators.required],
      [RfpCreateFormGroupFields.RdsEmployeeId]: [null, Validators.required],
    })
    form.disable();
    return form;
  }

  canCreate(): boolean {
    return this.rfpCreateFormGroup.valid && !this.creatingRfp
  }

  initialiseActiveAgreementFormField(){
    if(this.selectedMadCode !== undefined && this.selectedMadCode !== '' && this.selectedMadCode !== ' ' && this.selectedMadCode.length >=11){
      this.getAccountActiveAgreements(this.selectedMadCode)
    }
  }

  getAccountActiveAgreements(madCode: string){
    this.rfpApi.getAccountAgreements({madCode: madCode}).subscribe((resp: GetAccountAgreementsResp) => {
      let activeAgreements = resp.items.filter(f => f.isActive == true);
      if(activeAgreements !== null && activeAgreements !== undefined && activeAgreements?.length > 0){
        this.activeAccountAgreements = activeAgreements;
      }
    },
    (error) => {
      this.activeAccountAgreements = [];
    })
  }

  intialisePricingType(madCode: string){
    this.rfpApi.getAccountOverview({madCode: madCode}).subscribe((resp: GetAccountOverviewResp) => {
      this.negotiatingCustomerOverview = resp;
      this.primaryMadCodeOverview = resp;
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PricingTypeCd).setValue(resp.pricingType);
    },
    (error) => {
      this.negotiatingCustomerOverview = null;
      this.rfpCreateFormGroup.get(RfpCreateFormGroupFields.PricingTypeCd).setValue(null);
    })
  }

  createRFP() {
    this.creatingRfp = true;
    const formValues = this.rfpCreateFormGroup.value
    const rfpPrimaryContact: Partial<RfpPrimaryContact> = {
      contactName: fakerEN_US.person.fullName().substring(0, 100),
      contactEmail: fakerEN_US.internet.exampleEmail().substring(0, 255),
      contactFaxNumber: fakerEN_US.phone.number().substring(0, 20),
      countryCode: fakerEN_US.location.countryCode().substring(0, 2),
      addressLine1: fakerEN_US.location.streetAddress().substring(0, 255),
      cityName: fakerEN_US.location.city().substring(0, 100),
      stateCode: fakerEN_US.location.state({ abbreviated: true }).substring(0, 3),
      zipCode: fakerEN_US.location.zipCode().substring(0, 10),
    };
    const monthlyPotentialAmt = parseFloat(formValues[RfpCreateFormGroupFields.RevenuePotential]?.replace(/,/g, ''));
    const monthlyPromiseAmt = parseFloat(formValues[RfpCreateFormGroupFields.RevenuePromise]?.replace(/,/g, ''));
    const shipmentFileFormValue = formValues[RfpCreateFormGroupFields.ShipmentFileInd] === true;
    const inCycleIndicator = formValues[RfpCreateFormGroupFields.InCycleInd] === true;
    const retailRolloutInd = formValues[RfpCreateFormGroupFields.RetailRolloutInd] === true;
    const nassSubmissionInd = formValues[RfpCreateFormGroupFields.NassSubmissionInd] === true;
    const selectedActiveAgreement = formValues[RfpCreateFormGroupFields.ActiveAgreement];
    const selectedAgreementCustId =  selectedActiveAgreement?.accountNumber?.toString();
    const selectedAgreementSequence =  selectedActiveAgreement?.agreementSequenceNumber;
    const selectedAgreementVersion =  selectedActiveAgreement?.agreementCustomerVersion;
    const salesAgentId = formValues[RfpCreateFormGroupFields.SalesAgentId];
    const selectedPremiumServiceCodes: string[] = formValues[RfpCreateFormGroupFields.PremiumServiceCodes];
    const selectedPremiumTypeList = selectedPremiumServiceCodes !== undefined && selectedPremiumServiceCodes !== null ? selectedPremiumServiceCodes.join(';') : undefined;

    const hasShipmentFile = (inCycleIndicator === true
      || retailRolloutInd === true
      || shipmentFileFormValue == true
      || this.isRenegotiateRfp) ? 'Y' : 'N';

    if(this.isChangeAccessorialRfp || this.isRenegotiateRfp){
        if(selectedActiveAgreement){
          if(selectedActiveAgreement.isDpt === true){
            this.isContractedPricing = false;
          }
          else{
            this.isContractedPricing = true;
          }
        }
        else{ // no active agreement is selected check for shipment file and threshold
          this.isContractedPricing = hasShipmentFile && monthlyPotentialAmt >= this.dptThresholdAmount;
        }
    }
    else{
      this.isContractedPricing = hasShipmentFile && monthlyPotentialAmt >= this.dptThresholdAmount;
    }

    forkJoin([this.rfpApi.getLoggedInUser()]).subscribe(
      (response: [RfpMgmtUser]) => {

        const loggedInUser: RfpMgmtUser = response[0];

        const salesRfpHdr: Partial<SalesRfpHeader> = {
          sourceCrmOpportunityId:     "-1",
          negotiatingCustMadCd:       this.negotiatingCustomerOverview.madCode,
          negotiatingCustInstId:      `${this.negotiatingCustomerOverview.customerNumber}`,
          negotiatingCustAcctNm:      `${this.negotiatingCustomerOverview.name}`,
          negotiatingCustAddr:        this.negotiatingCustomerOverview.address,
          primaryAcctInstId:          `${this.primaryMadCodeOverview.customerNumber}`,
          pricingTypeCd:              formValues[RfpCreateFormGroupFields.PricingTypeCd],
          rfpTypeCd:                  formValues[RfpCreateFormGroupFields.RfpTypeCd],
          shipmentFileInd:            hasShipmentFile,
          thirdPartyLogisticsInd:     formValues[RfpCreateFormGroupFields.ThirdPartyLogisticsInd],
          thirdPartyLogisticsTypeCd:  formValues[RfpCreateFormGroupFields.ThirdPartyLogisticsForm]?.[RfpCreateFormGroupFields.ThirdPartyLogisticsTypeCd] ?? undefined,
          thirdPartyLogisticsMadCd:   formValues[RfpCreateFormGroupFields.ThirdPartyLogisticsForm]?.[RfpCreateFormGroupFields.ThirdPartyLogisticsMadCd] ?? undefined,
          monthlyPromiseAmt:          monthlyPromiseAmt,
          monthlyPotentialAmt:        monthlyPotentialAmt,
          salesAgentId:               salesAgentId ?? loggedInUser.employeeId,
          originApp:                  'devTool',
          rfpContractType:            this.isContractedPricing === true ? 'Contracted' : 'Dynamic',
          salesProgram:               retailRolloutInd === true 
                                      ? 'RetailRollout'
                                      : inCycleIndicator === true 
                                        ? 'InCycle'
                                        : this.isContractedPricing
                                          ? 'Standard'
                                          : 'OptimizedPricingProgram', 
          salesRegionCd:              formValues[RfpCreateFormGroupFields.SalesRegionForm]?.[RfpCreateFormGroupFields.SalesRegionCd] ?? "NONE",
          rdsEmployeeId:              formValues[RfpCreateFormGroupFields.SalesRegionForm]?.[RfpCreateFormGroupFields.RdsEmployeeId] ?? undefined,
          rfpPrimaryContact:          rfpPrimaryContact as RfpPrimaryContact,
          inCycleInd:                 inCycleIndicator === true ? 'Y' : 'N',
          areaCode:                   formValues[RfpCreateFormGroupFields.AreaCode],
          submitterRoleType:          nassSubmissionInd == true ? 'National Account Sales Specialist' : undefined,
          prevPricingAgrmtCustId:     selectedAgreementCustId ?? undefined,
          prevPricingAgrmtSeqNbr:     selectedAgreementSequence ?? undefined,
          prevPricingAgrmtCustVer:    selectedAgreementVersion ?? undefined,
          psaeEmployeeId:             formValues[RfpCreateFormGroupFields.PsaeEmployeeId] ?? undefined,
          requestSic:                 formValues[RfpCreateFormGroupFields.RequestSicCode] ?? undefined,
          updateLocationType:         formValues[RfpCreateFormGroupFields.UpdateLocationType] ?? undefined,
        }

        if(this.isPremiumService){
          if(selectedPremiumServiceCodes !== undefined && selectedPremiumServiceCodes !== null){
            for (let i = 0; i < selectedPremiumServiceCodes.length; i++) {
              if(selectedPremiumServiceCodes[i].toLocaleUpperCase() === "MABD"){
                const mabdQuestionnaire: RfpMgmtPremiumServiceQuestionnaire = {
                  premiumServiceType: "MABD",
                  questions: [{
                    question: "What pain point is the customer trying to resolve with an MABD service?",
                    answer: fakerEN_US.word.adjective(50),
                  },
                  {
                    question: "Who is your current provider for MABD?",
                    answer: fakerEN_US.word.adjective(50),
                  },
                  {
                    question: "What are the consignee's delivery requirements?",
                    answer: fakerEN_US.word.adjective(50),
                  }]
                };
                this.premiumServiceQuestionnaire.push(mabdQuestionnaire);
              }
              if(selectedPremiumServiceCodes[i].toLocaleUpperCase() === "MEXICO"){
                // We will change the mexico questions later to differentiate from MABD for testing
                const mexicoQuestionnaire: RfpMgmtPremiumServiceQuestionnaire = {
                  premiumServiceType: "Mexico",
                  questions: [{
                    question: "Do the customer ship to and/or from Mexico?",
                    answer: "To " + fakerES_MX.location.city() + " From " + fakerES_MX.location.city()
                  },
                  {
                    question: "Is the customer currently set up with a US and Mexico broker?",
                    answer: faker.helpers.arrayElement(['Yes', 'No'])
                  },
                  {
                    question: "Is the customer able to track your shipments door to door?",
                    answer: faker.helpers.arrayElement(['Yes', 'No'])
                  }]
      
                };
                this.premiumServiceQuestionnaire.push(mexicoQuestionnaire);
              }
            }
          }

          const additionalFields: SkeletonRfpAdditionalFields = {
            premiumServiceType: selectedPremiumTypeList,
            solution: undefined,
            serviceStartDate: fakerEN_US.date.future().toISOString().substring(0,10),
            confidence: faker.helpers.arrayElement(['Good', 'High', 'Poor', 'Very Poor', 'Average']),
            annualAmount: parseFloat(fakerEN_US.finance.amount({ dec: 2, min: 50000, max: 50000000 })),
            xpoCurrentAnnualRevenue: parseFloat(fakerEN_US.finance.amount({ dec: 0, min: 100000, max: 10000000 })),
            incrementalGrowth: parseFloat(fakerEN_US.finance.amount({ dec: 2, min: 1000, max: 100000 })),
            incumbentCompetitor: fakerEN_US.word.noun(50),
            lostToCompetitor: fakerEN_US.word.noun(50),
            primaryCompetitor: fakerEN_US.word.noun(50),
            premiumServiceQuestionsJson: JSON.stringify(this.premiumServiceQuestionnaire),
            premiumServiceTypeStatus: "", //TODO: Replace with new fields
          };
          salesRfpHdr.additionalFields = additionalFields;
        }

        const createRequest: Partial<CreateRfpRqst> = {
          salesRfp: {
            salesRfpHdr: (salesRfpHdr as SalesRfpHeader),
          }
        }

        this.rfpApi.createRfp(createRequest as CreateRfpRqst).subscribe(
          (response: CreateRfpResp) => {
            this.snackbar.open(
              {
                message: "Successfully Created RFP ID: " + response.salesRfpId,
                detailedMessage: "Contract Type: " + response.rfpContractType,
                linkRoute: response.salesRfpUrl,
                status: "success",
                matConfig: {
                  duration: 8000
                }
              }
            )
          },
          (error) => {
            this.snackbar.error("Failed Creating RFP, Please Try Again")
          }
        ).add( () => { this.creatingRfp = false; });
      },
      (error) => {
        this.snackbar.error("Failed Getting Account Information for MAD Codes")
        this.creatingRfp = false;
      }
    )

  }

  disableRfpTypeCode(rfpTypeCode: LookupCodeNamePair){
    switch(rfpTypeCode.code){
      // if the RFP type is Conversion, disable it as one crm uses it for DP to Contracted RFPs
      case "Conversion":
        return true;
      default: 
        return false;
    }
  }

  getRfpTypeOptionTooltip(reasonCode: LookupCodeNamePair){
    if(this.disableRfpTypeCode(reasonCode)){
      return "This is a ONECRM-only rfp type code"
    }
    return null;
  }

  formattedDptThreshold(dptThreshold: number): string{
    return new CurrencyPipe("en-US").transform(dptThreshold, 'USD', 'symbol', '1.0-0');
  }
}